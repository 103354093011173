import store from '@/store'

//路由守卫
export default function(router){
      router.beforeEach((to,from,next)=>{
         /* 路由发生变化修改页面title */
      if (to.meta.title) {
        document.title = to.meta.title
      }
        //重定向至首页
        if(to.fullPath=='/'){
          console.log("123")
          next({path:'/home'})
        }else{
          next()
        }
  
    })
    return router
}