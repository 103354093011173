import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    show_tabs:true,//是否显示tab栏
  },
  mutations: {
    //改变页面路径更新vuex
    showTabs(state, event){
      state.show_tabs = event
    }
  },
  actions: {
    
  },
  modules: {

  }
})
