import Vue from 'vue'
import Router from 'vue-router'
import guard from './guard'

Vue.use(Router)

//重复跳转不会报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'tabbar',
    component: () => import('@/views/tabs/tabbar'),
    meta: {
      title: '首页'
    },
    children:[
      {
        path: 'home',     // 主页
        component: () => import('@/views/tabs/children/home'),      // 路由懒加载
        meta: { title: '首页' }
      },
      {
        path: 'list',    // 列表
        component: () => import('@/views/tabs/children/list'),     // 路由懒加载
        meta: { title: '列表' }
      },
      {
        path: 'message',    // 列表
        component: () => import('@/views/tabs/children/message'),     // 路由懒加载
        meta: { title: '留言板' }
      },
      {
        path: 'daily',    // 列表
        component: () => import('@/views/tabs/children/daily'),     // 路由懒加载
        meta: { title: '日常' }
      },
      {
        path: 'article',    // 列表
        component: () => import('@/views/tabs/children/article'),     // 路由懒加载
        meta: { title: '文章详情' }
      },
    ]
  },
  {
    path: '/ceshi',    // 列表
    component: () => import('@/views/Home'),     // 路由懒加载
    meta: { title: '111' }
  },

]


export default guard(new Router({
  mode:'hash',
  routes
}))
