const LOCALURL = "https://www.baiyuhappy.xyz/api"//域名
const URL = {//接口url
    homeLogin: LOCALURL + '/homeLogin',//无感登录
    userLogin: LOCALURL + '/userLogin',//用户登录
    getHomeInfo: LOCALURL + '/getHomeInfo',//获取首页内容
    getArticleType: LOCALURL + '/getArticleType',//获取文章分类
    getArticleByType: LOCALURL + '/getArticleByType',//根据类型获取文章列表
    getDaily: LOCALURL + '/getDaily',//获取日常接口
    getArticleById:LOCALURL + '/getArticleById',//根据id获取文章详情
    addComment:LOCALURL + '/addComment',//添加评论
    getComment:LOCALURL + '/getComment',//获取留言板
    userRegister:LOCALURL + '/userRegister',//用户注册
    getCommentByArticleId:LOCALURL + '/getCommentByArticleId',//根据文章id获取回复详情
    giveFabulous:LOCALURL + '/giveFabulous',//点赞接口
    getStatistics:LOCALURL + '/getStatistics',//站点统计接口
}

module.exports = URL