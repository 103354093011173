import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import './utils/request'
import 'element-ui/lib/theme-chalk/index.css'
import url from '@/api.js' 
import { WOW } from 'wowjs'//页面滚动效果
import '../node_modules/wowjs/css/libs/animate.css'//wow.js需要
import { timeFilter } from './filters/index.js'//引入过滤器
import VueLetterAvatar from 'vue-letter-avatar';//引入字母头像
import LetItSnow from 'vue-let-it-snow';//雪花飘落效果


Vue.prototype.$wow=WOW;

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueLetterAvatar);
Vue.use(LetItSnow);

Vue.prototype.$axios = axios
Vue.prototype.$url = url//接口地址
Vue.prototype.$upload_img = 'https://www.baiyuhappy.xyz/api/uploadImg';//图片上传地址
// Vue.prototype.$api = 'http://106.15.186.228:7001'

Vue.filter('timeFilter',timeFilter)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
